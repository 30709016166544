import React, { useState } from 'react'
import InputFieldItem from './InputFieldItem'
import './style.css'
import { Theme } from '../../../types/types'
import sendFormToMailchimp from '../../../utils/sendFormToMailchimp'
import { navigate } from 'gatsby'
import Recaptcha from 'react-google-recaptcha'
import { Alert } from 'reactstrap'

interface Props {
    props: {
        header_text: string
        background_image: string
        input_fields: { field: string }[]
        development_tag: string
        form_redirect: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => {
    const [inputs, setInputs] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [recaptcha, setRecaptcha] = useState<boolean>(false)
    const [checkbox, setCheckbox] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const [firstName, lastName, email, phone] = inputs
        const development = props.development_tag

        if (!email) {
            setError('Please enter a valid email.')
            return
        } else if (!checkbox) {
            setError('Please accept the Terms and Conditions.')
            return
        } else if (!recaptcha) {
            setError('Please complete the recaptcha.')
            return
        }
        if (!email || !development || !recaptcha || !checkbox) {
            return
        }
        setLoading(true)
        sendFormToMailchimp(firstName, lastName, email, phone, development)
            .then(
                () =>
                    (window.location.href = props.form_redirect
                        ? props.form_redirect
                        : '/')
            )
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
    }
    return (
        <section
            className="contact_form"
            style={{
                backgroundImage: `url(${props.background_image})`,
            }}
        >
            <form onSubmit={event => handleOnSubmit(event)}>
                <div id="contact-form" className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {error ? (
                                <Alert color="danger">{error}</Alert>
                            ) : null}
                            <h3 style={{ color: theme.banner_text_color }}>
                                {props.header_text}
                            </h3>
                            {props.input_fields
                                ? props.input_fields.map(
                                      (inputField, index) => (
                                          <InputFieldItem
                                              key={index}
                                              index={index}
                                              inputField={inputField.field}
                                              inputs={inputs}
                                              setInputs={setInputs}
                                          />
                                      )
                                  )
                                : null}
                        </div>
                        <div className="col-lg-8">
                            <div className="recaptcha">
                                <p>
                                    <input
                                        type="checkbox"
                                        checked={checkbox}
                                        onChange={() => setCheckbox(!checkbox)}
                                    />
                                    <span>
                                        I accept the Terms and Conditions of the
                                        Privacy Policy
                                    </span>
                                </p>
                                <Recaptcha
                                    sitekey={
                                        '6Lduht4UAAAAAEIKXM082bjRIYl3DbQnKDKl0TL_'
                                    }
                                    onChange={result =>
                                        result
                                            ? setRecaptcha(true)
                                            : setRecaptcha(false)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <input
                                type="submit"
                                value={loading ? 'Loading...' : 'Send >'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </section>
    )
}
