import headerImageWithText from './header_image_with_text'
import doubleImageRightAlignedText from './double_image_right_aligned_text'
import imageRightTextLeft from './image_right_text_left'
import horizontalImageListView from './horizontal_image_list_view'
import amenitiesMap from './amenities_map'
import twoImageContainersWithText from './two_image_containers_with_text'
import circleImageWithText from './circle_image_with_text'
import imageWithTextLeft from './image_with_text_left'
import galleryGrid from './gallery_grid'
import twoImageWithTopHeaderText from './two_image_with_top_header_text'
import doubleImageLeftAlignedText from './double_image_left_aligned_text'
import imageLeftTextRight from './image_left_text_right'
import faq from './faq'
import contactForm from './contact_form'
import inclusionsList from './inclusions_list'
import floorplanList from './floorplan_list'

export const HeaderImageWithText = headerImageWithText
export const DoubleImageRightAlignedText = doubleImageRightAlignedText
export const ImageRightTextLeft = imageRightTextLeft
export const HorizontalImageListView = horizontalImageListView
export const AmenitiesMap = amenitiesMap
export const TwoImageContainersWithText = twoImageContainersWithText
export const CircleImageWithText = circleImageWithText
export const ImageWithTextLeft = imageWithTextLeft
export const GalleryGrid = galleryGrid
export const TwoImageWithTopHeaderText = twoImageWithTopHeaderText
export const DoubleImageLeftAlignedText = doubleImageLeftAlignedText
export const ImageLeftTextRight = imageLeftTextRight
export const FAQ = faq
export const ContactForm = contactForm
export const InclusionsList = inclusionsList
export const FloorplanList = floorplanList

export default [
    HeaderImageWithText,
    DoubleImageRightAlignedText,
    ImageRightTextLeft,
    HorizontalImageListView,
    AmenitiesMap,
    TwoImageContainersWithText,
    CircleImageWithText,
    ImageWithTextLeft,
    GalleryGrid,
    TwoImageWithTopHeaderText,
    DoubleImageLeftAlignedText,
    ImageLeftTextRight,
    FAQ,
    ContactForm,
    InclusionsList,
    FloorplanList,
]
