import React from 'react'
import { TextDecoration, Theme } from '../../../types/types'
import './style.css'

interface Props {
    props: {
        image: string
        header_text: string
        main_text: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="image_left_text_right">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6">
                    <div
                        className="image"
                        style={{ backgroundImage: `url(${props.image})` }}
                    />
                </div>
                <div className="col-lg-6 vertical-center-container">
                    <div className="text-container">
                        <h2 style={{ color: theme.header_text_color }}>
                            {props.header_text}
                        </h2>
                        <p>{props.main_text}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)
