import React from 'react'
import ContentBuilder from '../components/content_builder'
import Layout from '../components/layout'
import { Footer } from '../components'
import SEO from '../components/seo'

const SectionMapper = acf_layout =>
    acf_layout.map(layout => {
        console.log(layout)
        switch (layout.acf_fc_layout) {
            case 'header_image_with_text':
                return { contentBuilder: ContentBuilder[0], content: layout }
            case 'double_image_right_aligned_text':
                return { contentBuilder: ContentBuilder[1], content: layout }
            case 'image_right_text_left':
                return { contentBuilder: ContentBuilder[2], content: layout }
            case 'horizontal_image_list_view':
                return { contentBuilder: ContentBuilder[3], content: layout }
            case 'amenities_map':
                return { contentBuilder: ContentBuilder[4], content: layout }
            case 'two_image_containers_with_text':
                return { contentBuilder: ContentBuilder[5], content: layout }
            case 'circle_image_with_text':
                return { contentBuilder: ContentBuilder[6], content: layout }
            case 'image_with_text_left':
                return { contentBuilder: ContentBuilder[7], content: layout }
            case 'gallery_grid':
                return { contentBuilder: ContentBuilder[8], content: layout }
            case 'two_image_with_top_header_text':
                return { contentBuilder: ContentBuilder[9], content: layout }
            case 'double_image_left_aligned_text':
                return { contentBuilder: ContentBuilder[10], content: layout }
            case 'image_left_text_right':
                return { contentBuilder: ContentBuilder[11], content: layout }
            case 'faq':
                return { contentBuilder: ContentBuilder[12], content: layout }
            case 'contact_form':
                return { contentBuilder: ContentBuilder[13], content: layout }
            case 'inclusions_list':
                return { contentBuilder: ContentBuilder[14], content: layout }
            case 'floorplan_list':
                return { contentBuilder: ContentBuilder[15], content: layout }
        }
    })

export default ({ pageContext }: { pageContext: any }) => {
    const sections = SectionMapper(pageContext.content)
    return (
        <Layout>
            <SEO title={pageContext.title} />
            {sections.map((Section, index) => (
                <Section.contentBuilder
                    key={index}
                    props={Section.content}
                    theme={pageContext.theme}
                />
            ))}
            <Footer props={pageContext.footer} theme={pageContext.theme} />
        </Layout>
    )
}
