import React from 'react'
import { InputField } from '../../../types/types'

interface Props {
    index: number
    inputField: string
    inputs: string[]
    setInputs: Function
}

export default ({ index, inputField, inputs, setInputs }: Props) => (
    <input
        key={index}
        style={{
            color: 'white',
            borderBottomColor: 'white',
        }}
        type="text"
        placeholder={inputField}
        onChange={event => {
            inputs[index] = event.target.value
            setInputs([...inputs])
        }}
        value={inputs[index]}
    />
)
