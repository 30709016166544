import React from 'react'
import { FloorPlan } from '../../../types/types'

const roomTypeImage = [
    require('../../../images/room_type_0.svg'),
    require('../../../images/room_type_1.svg'),
    require('../../../images/room_type_2.svg'),
    require('../../../images/room_type_3.svg'),
]

interface Props {
    floorplan: FloorPlan
}

export default ({ floorplan }: Props) => (
    <div className="floorplan-item">
        <img src={floorplan.image} />
        <div className="title-price-container">
            <h4>{floorplan.address}</h4>
            <h6>
                {floorplan.price} ({floorplan.location})
            </h6>
        </div>
        <ul>
            {floorplan.rooms.map((room, index) => (
                <li key={index}>
                    <span>{room.amount}</span>
                    <div
                        className={`room-type room-${room.room_type.value}`}
                        style={{
                            backgroundImage: `url('${
                                roomTypeImage[parseInt(room.room_type.value)]
                            }')`,
                        }}
                    />
                </li>
            ))}
        </ul>
        <p>{floorplan.description}</p>
        <a href={floorplan.link_address} target="_blank">{floorplan.link_text}</a>
    </div>
)
