import React from 'react'
import './style.css'
import { TextDecoration, Theme } from '../../../types/types'

interface Props {
    props: {
        background_image: string
        main_image: string
        header_section_paragraph: string
        header_section_text: string
        main_section_header_text: string
        main_section_main_text: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="double_image_right_aligned_text container-fluid">
        <div
            className="row first-section"
            style={{ backgroundImage: `url(${props.background_image})` }}
        >
            <div className="image-overlay" />
            <div className="col-lg-12">
                <div className="text-container">
                    <p>{props.header_section_paragraph}</p>
                    <h2 style={{ color: theme.banner_text_color }}>
                        {props.header_section_text}
                    </h2>
                </div>
            </div>
        </div>
        <div className="row second-section">
            <div className="col-lg-6 main-image">
                <div style={{ backgroundImage: `url(${props.main_image})` }} />
            </div>
            <div className="col-lg-6 main-text-container">
                <div className="text-container">
                    <h4 style={{ color: theme.header_text_color }}>
                        {props.main_section_header_text}
                    </h4>
                    <p>{props.main_section_main_text}</p>
                </div>
            </div>
        </div>
    </section>
)
