import React from 'react'

interface Props {
    onClick: Function
    arrowDirection: string
    arrowImage: string
}

export default ({ onClick, arrowDirection, arrowImage }: Props) => (
    <div className={`arrow ${arrowDirection}`} onClick={() => onClick()}>
        <img src={arrowImage} />
    </div>
)
