import React from 'react'
import './style.css'
import { Link } from 'react-scroll'
import { Theme } from '../../../types/types'

interface Props {
    props: {
        background_image: string
        header_image: string
        location_text: string
        paragraph: string
    }
    theme: Theme
}

const downArrow = require('../../../images/down_arrow.png')

export default ({ props, theme }: Props) => (
    <section
        className="header_image_with_text"
        style={{
            backgroundImage: `url(${props.background_image})`,
        }}
    >
        <div className="top-header-container">
            <div>{props.location_text}</div>
            <Link to="contact-form" offset={-250} smooth={true} duration={1000}>
                Register your interest
            </Link>
        </div>
        <div className="image-overlay" />
        <div />
        <div className="header-container">
            <div
                className="image"
                style={{ backgroundImage: `url(${props.header_image})` }}
            />
            <h6>{props.location_text}</h6>
            <p>{props.paragraph}</p>
        </div>
        <div className="bottom-container">
            <Link
                to={'header_image_with_text'}
                offset={1000}
                smooth={true}
                duration={500}
            >
                <div>SCROLL</div>
                <img src={downArrow} />
            </Link>
        </div>
    </section>
)
