import Axios from 'axios'
import Querystring from 'querystring'

export default (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    development: string
) => {
    const data = Querystring.stringify({
        email,
        development,
        firstName,
        lastName,
        phone,
    })
    return Axios({
        url: 'https://porterdavis.com.au/api/subscribeUserWithEmail.php',
        method: 'POST',
        data,
        headers: {
            Accept: 'application/x-www-form-urlencoded',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
}
