import React, { useState } from 'react'
import { QAItem } from '../../../types/types'

interface Props {
    key: number
    qaItem: QAItem
}

export default ({ key, qaItem }: Props) => {
    const [show, setShow] = useState<boolean>(false)
    return (
        <li key={key}>
            <h4 onClick={() => setShow(!show)}>{qaItem.question}</h4>
            {show ? <p>{qaItem.answer}</p> : null}
        </li>
    )
}
