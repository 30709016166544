import React from 'react'
import './style.css'
import { Theme } from '../../../types/types'

interface Props {
    props: {
        image: string
        header_text: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section
        className="image_with_text_left container-fluid"
        style={{ backgroundImage: `url(${props.image})` }}
    >
        <div className="row">
            <div className="text-container">
                <h2 style={{ color: theme.banner_text_color }}>
                    {props.header_text}
                </h2>
            </div>
        </div>
    </section>
)
