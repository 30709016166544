import React from 'react'
import { TextDecoration, QAItem, Theme } from '../../../types/types'
import FaqListItem from './FaqListItem'
import './style.css'

interface Props {
    props: {
        header_text: string
        questions: QAItem[]
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="faq container">
        <div className="row">
            <div className="col-lg-12">
                <h2 style={{ color: theme.header_text_color }}>
                    {props.header_text}
                </h2>
                <ul>
                    {props.questions.map((qa, index) => (
                        <FaqListItem key={index} qaItem={qa} />
                    ))}
                </ul>
            </div>
        </div>
    </section>
)
