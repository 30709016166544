import React from 'react'
import { TextDecoration, Theme } from '../../../types/types'
import './style.css'

interface Props {
    props: {
        header_title: string
        left_image: string
        right_image: string
        left_image_header_text: string
        left_image_main_text: string
        right_image_header_text: string
        right_image_main_text: string
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <section className="two_image_containers_with_text container">
        <div className="row">
            <h2 style={{ color: theme.header_text_color }}>
                {props.header_title}
            </h2>
        </div>
        <div className="row">
            <div className="col-lg-6 left-container">
                <div
                    className="image"
                    style={{ backgroundImage: `url(${props.left_image})` }}
                />
                <h3 style={{ color: theme.header_text_color }}>
                    {props.left_image_header_text}
                </h3>
                <p>{props.left_image_main_text}</p>
            </div>
            <div className="col-lg-6 right-container">
                <div
                    className="image"
                    style={{ backgroundImage: `url(${props.right_image}` }}
                />
                <h3 style={{ color: theme.header_text_color }}>
                    {props.right_image_header_text}
                </h3>
                <p>{props.right_image_main_text}</p>
            </div>
        </div>
    </section>
)
