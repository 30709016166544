import React from 'react'
import './style.css'
import { Theme } from '../../types/types'

interface Props {
    props: {
        address: string
        open_hours: { day: string; hours: string }[]
        copywright: string
        link_address: string
        link_text: string
        phone_number: string
        real_phone_number: string
        partners: { logo: string }[]
    }
    theme: Theme
}

export default ({ props, theme }: Props) => (
    <footer>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-3">
                    <div className="address">
                        <p>Visit</p>
                        <p>{props.address}</p>
                    </div>
                    <div className="open-hours">
                        <p>Open Hours</p>
                        {props.open_hours.map((hour, index) => (
                            <p key={index}>
                                {hour.day} : {hour.hours}
                            </p>
                        ))}
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="phone-number">
                        <p>Call</p>
                        <a href={`tel: ${props.real_phone_number}`}>{props.phone_number}</a>
                    </div>
                </div>
                <div className="col-lg-6 partners-container">
                    <p>This neighbourhood was built with</p>
                    <div className="row partners">
                        {props.partners.map((partner, index) => (
                            <div key={index} className="col-sm-4">
                                <img src={partner.logo} width={200} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row copywright-container">
                <div className="col-sm-6">
                    <p>{props.copywright}</p>
                    <a href={`${props.link_address}`} className="visit-pd-link-left" target="_blank">
                        {props.link_text} >
                    </a>
                </div>
                <div className="col-sm-6">
                    <a href="https://porterdavis.com.au/" className="visit-pd-link" target="_blank">
                        Visit Porter Davis website >
                    </a>
                </div>
            </div>
        </div>
    </footer>
)
